import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { darken } from "polished"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Button from "../components/Button"
import Carousel from "../components/Carousel"
import Layout from "../components/Layout"
import { colors } from "../components/styles"

const BigButtons = styled.div`
  margin: 100px auto;
  text-align: center;
  font-size: 1.1rem;

  > * + * {
    margin-left: 2rem;
  }

  @media (max-width: 800px) {
    > * {
      display: block;
      margin: 25px;
    }
  }
`

export default function IndexPage() {
  const [latestBooks, setLatestBooks] = useState([])

  useEffect(() => {
    ;(async () => {
      const res = await fetch(
        "https://api.institutbiblique.be/recensions?take=9"
      )
      const data = await res.json()
      if (!res.ok) throw data
      setLatestBooks(data)
    })()
  }, [])

  return (
    <Layout bigHeader={true}>
      <div
        style={{
          position: "relative",
          background: darken(0.55, colors.greyLight),
          color: "#fff",
          fontSize: "1.6rem",
          textAlign: "center",
          lineHeight: 1.7,
          letterSpacing: "0.05rem",
          textTransform: "uppercase",
          textShadow: "#000 0 0 40px",
        }}
      >
        <StaticImage
          style={{
            objectFit: "cover",
            position: "absolute",
            width: "100%",
            height: "100%",
            mixBlendMode: "multiply",
            opacity: 0.5,
          }}
          layout="fullWidth"
          alt=""
          src={"../images/header.jpg"}
        />
        <div
          style={{
            position: "relative",
            padding: "100px 25px",
          }}
        >
          Des présentations de livres
          <br />
          passés au crible des écritures
          <br />
          proposées par l'Institut Biblique de Bruxelles
        </div>
      </div>

      {latestBooks && (
        <Carousel title="Les dernières recensions" books={latestBooks} />
      )}

      <section
        style={{
          lineHeight: 1.7,
          margin: "75px auto",
          maxWidth: 1000,
          fontSize: "1.1em",
        }}
      >
        <p
          style={{
            color: colors.yellow,
            fontSize: "2em",
            fontWeight: 900,
            letterSpacing: "0.1em",
            textAlign: "center",
            textTransform: "uppercase",
            marginBottom: "2rem",
          }}
        >
          Bienvenue !
        </p>
        <p>
          Lors du centenaire de l'
          <a href="https://www.institutbiblique.be">
            Institut Biblique de Bruxelles
          </a>{" "}
          en septembre 2019, nous avons annoncé la décision de publier une
          recension chaque mois. Nous avons expliqué à l'époque : « Le but est
          de vous orienter vers des livres édifiants et utiles et, là où cela
          pourrait être nécessaire, de vous mettre en garde contre des idées
          bibliquement nocives. ». Par la grâce de Dieu, nous avons vite compris
          qu'en faisant appel à des ancien(ne)s étudiant(e)s et à des amis, nous
          pouvons assurer le rythme d'une publication tous les 15 jours. L'idée
          de lancer ce site s'est imposée du fait du nombre de recensions qui
          voient le jour en conséquence. Le nom du site (« examen de livres », «
          Bibliodok » étant une abréviation de « Bibliodokimasia ») reflète
          notre but : nous aspirons à tout passer au crible des Écritures en vue
          de servir notre lectorat.
        </p>
        <p>
          Nous demandons à nos recenseurs de couvrir les considérations
          suivantes : (1) Pour qui le livre pourrait-il être utile ? ; (2)
          Pourquoi ? Quel intérêt ? Quel contenu ? ; (3) Forces ; (4) Faiblesses
          ; (5) Orientation théologique (surtout si le livre dérape…)/lien avec
          l'Evangile ; (6) Lien avec d'autres livres dans ce domaine (si
          possible/connu) ; (7) Conclusion – bilan.
        </p>
        <p>
          Il ne s'agit pas d'un site de livres recommandés en tant que tel. Mais
          nous attirons votre attention sur deux articles qui proposent des
          recommandations selon plusieurs catégories : «{" "}
          <a href="https://www.institutbiblique.be/article?x=constituer-une-bibliotheque-de-base-en-theologie">
            Constituer une bibliographie de base en théologie
          </a>{" "}
          » et «{" "}
          <a href="https://www.institutbiblique.be/article?x=quels-ouvrages-de-reference-dans-la-bibliotheque-d-un-predicateur">
            Quels ouvrages de référence dans la bibliothèque d'un prédicateur ?
          </a>{" "}
          ».
        </p>
        <p>
          Nous prions donc afin que ce site soit hautement bénéfique pour
          éclairer, édifier et équiper les serviteurs de l'Évangile (cf. la{" "}
          <a href="https://www.institutbiblique.be/vision">vision de l'IBB</a>
          ). Nous vous encourageons à profiter d'autres ministères que nous
          proposons, tels que nos{" "}
          <a href="https://www.institutbiblique.be/videos">
            vidéos hebdomadaires
          </a>{" "}
          (« Mini-Méditations du Mercredi » : voir notre{" "}
          <a
            href="https://www.youtube.com/channel/UCPupmUX_0jxJGefq7SuYvBw"
            target="ibbYoutube"
          >
            chaîne YouTube
          </a>
          ), nos nombreux{" "}
          <a href="https://www.institutbiblique.be/articles">articles</a> et
          surtout des{" "}
          <a href="https://www.institutbiblique.be/formations">formations</a>{" "}
          qui se déroulent sur place, à Bruxelles. Nous sommes{" "}
          <a href="https://www.institutbiblique.be/a-votre-service">
            à votre service
          </a>{" "}
          !
        </p>
        <p>Bonne navigation sur ce site !</p>

        <StaticImage
          style={{
            maxWidth: 300,
            display: "block",
            margin: "50px auto",
          }}
          backgroundColor="white"
          placeholder="blurred"
          alt="Bibliodokimasia"
          src={"../images/bibliodokimasia.svg"}
        />
      </section>

      <BigButtons>
        <Link to="/recherche">
          <Button big>Rechercher une recension</Button>
        </Link>
      </BigButtons>
    </Layout>
  )
}
