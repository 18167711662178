import { lighten } from "polished"
import React from "react"
import SwiperCore, { Autoplay } from "swiper"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import BookResult from "./BookResult"
import { colors } from "./styles"

SwiperCore.use([Autoplay])

export default function Carousel({ title, books }) {
  return (
    <div
      style={{
        background: colors.greyLight,
        color: colors.greyDark,
        padding: "35px 25px",
      }}
    >
      <div
        style={{
          color: lighten(0.1, colors.grey),
          marginLeft: 10,
          marginBottom: "1.4rem",
          fontWeight: 800,
          fontSize: "1.1rem",
          letterSpacing: "0.05rem",
          textTransform: "uppercase",
        }}
      >
        {title}
      </div>

      <Swiper
        loop={true}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        breakpoints={{
          800: {
            spaceBetween: 50,
            slidesPerView: 2,
            initialSlide: 2,
          },
          1200: {
            spaceBetween: 50,
            slidesPerView: 3,
            initialSlide: 3,
          },
        }}
      >
        {books.map((book) => (
          <SwiperSlide key={book.id}>
            <BookResult result={book} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
